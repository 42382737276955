/* eslint-disable */
import axios from "axios";
import Vue from "vue";
/**
 *  获取指定域名
 * @returns {string}
 */
function getApiDomain() {
    //如果是钉钉
    if (window.location.hostname === "dingtalk-web.1dq.com" || window.location.hostname === "dingtalk-web8.1dq.com")
        return "https://dingtalk-api.1dq.com"

    return process.env.NODE_ENV === "development" ? "http://pdf8.1dq.com:5556" : "https://api.pdfw.com"
}


/**
 * 公共通用JS
 */
export default {
    API_ROOT: getApiDomain(),
    version: "8.6.2",
    alert(content) {

        // return Vue.swal(content);

        document.getElementById("w-alert-popup-white").style.display = "block"
        document.getElementById("w-alert-content").innerHTML = content


        let w = document.body.clientWidth;//浏览器的宽
        let h = window.innerHeight;//浏览器的高

        let boxes = document.getElementsByClassName("w-error-inner")[0];
        let boxWid = boxes.scrollWidth;//获取div的宽
        let boxHgt = boxes.scrollHeight;//获取div的高
        console.log(boxWid + " " + boxHgt);
        boxes.style.left = w / 2 - (boxWid / 2) + "px";
        boxes.style.top = h / 2 - (boxHgt / 2) - 44 + "px";
    },

    hideAlert() {
        document.getElementById("w-alert-popup-white").style.display = "none"
        document.getElementById("w-alert-content").innerHTML = ""
    },


    /**
     * 显示加载
     */
    loading() {

        try {
            document.getElementById("loading-start-public-box").innerHTML;
        } catch (err) {
            var div = document.createElement("div");
            div.id = "loading-start-public-bg";
            document.body.appendChild(div);

            var box = document.createElement("div");
            box.id = "loading-start-public-box";
            document.body.appendChild(box);
            var img = document.createElement("img");
            img.src = "/images/loading.gif";
            box.appendChild(img);

        }
        document.getElementById("loading-start-public-bg").style.display = "block"
        document.getElementById("loading-start-public-box").style.display = "block"
    },
    /**
     * 隐藏加载
     */
    hideLoading() {

        try {
            document.getElementById("loading-start-public-bg").style.display = "none"
            document.getElementById("loading-start-public-box").style.display = "none"
        } catch (err) {

        }
    },

    getClientId() {
        /**
         * @return {string}
         */
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
    },
    /**
     * 获取header
     * @returns {any|{clientType: string, clientId: (string|string), appId: (string|string), version: default.version, orgId: (string|string), token: (string|string)}}
     */
    getHeaders() {
        return {
            "appId": this.getQuery("appId"),
            "token": this.getQuery("token"),
            "clientType": "WEB",
            "clientId": this.getClientId(),
            "version": this.version,
            "orgId": this.getQuery("orgId"),
            "Content-Type": "application/json;chartset=uft-8"
        };
    },

    getQuery(name) {
        let query = window.location.hash;
        let str = query.split("?");
        let j_query = str[1] ? str[1] : str[0];

        let vars = j_query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] === name) {
                return pair[1];
            }
        }
        return "";
    },
    /**
     * 获取远程内容
     * @param url
     * @param data
     * @param successFunction
     * @param errorFunction
     * @param hideLogin
     */
    post(url, data, successFunction, errorFunction, hideLogin) {

        let params = {
            method: 'post',
            url: url.includes("http") ? url : this.API_ROOT + url,
            data: data,
            timeout: 60 * 1000,
            headers: this.getHeaders()
        };
        axios(params).then(response => {
            successFunction && successFunction(response.data)
        }).catch(error => {
            errorFunction && errorFunction(error)
            this.hideLoading()
        });
    }

};

