import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import api from "./conf/js/api.js"

Vue.use(api)


import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#007aff'
};

Vue.use(VueSweetalert2, options);

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
    lazyComponent: false,
    preLoad: 1,
    dispatchEvent : false,
})

import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

//路由前置操作
router.beforeEach((to, from, next) => {
    // NProgress.start();
    next();
})

// 结束操作
router.afterEach(transition => {
    // setTimeout(()=>{
    //     NProgress.done();
    // }, 1000)
});



Vue.config.productionTip = false
Vue.prototype.api = api
new Vue({ router,  render: h => h(App) }).$mount('#app')
