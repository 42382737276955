import Vue from "vue";
import Router from "vue-router";
// 引入其他路由文件

import publicJs from "./routes/public.js";
/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error);
};

Vue.use(Router);


export default new Router({
     mode: 'hash',
    routes: [
        ...publicJs
    ],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});
