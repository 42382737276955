<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import "./assets/style/style.css"

export default {
  name: 'App',
  components: {

  },
  mounted() {

  },
  methods : {



  }
}
</script>

<style>
:root {
  --red: #e61717;
  --blue: #5d78ff;
  --color2: #5d78ff;
}

#nprogress .bar {
  background: var(--blue) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--blue), 0 0 5px var(--blue);
}

#nprogress .spinner-icon {
  border-top-color: var(--blue);
  border-left-color: var(--blue);
}

.canvasWrapper canvas {
  border-radius: 4px;
}
.tpl-box-v31 .page {
  display: none;
}

.showCanvas .page {
    display: block;
    -webkit-animation-name: loading; /*动画名称*/
    -webkit-animation-duration: 1s; /*动画持续时间*/
    -webkit-animation-iteration-count: 1; /*动画次数*/
    -webkit-animation-delay: 0s; /*延迟时间*/
}

@keyframes loading {
  0% {
    opacity:0.3;
  }
  100% {
    opacity:1;
  }
}

.PinturaRadioGroupOptions li svg {
  /*display: none;*/
}

.PinturaRadioGroupOptions li label {
  white-space: nowrap;
}

.PinturaControlList li button {
  white-space: nowrap;
}
</style>

