export default [
    {
        path: '/viewer',
        name: 'viewer',
        component: () => import( '@/viewer/index.vue'),
        meta: {
            title: '文档预览'
        }
    },
    {
        path: '/viewer/excel',
        name: 'viewer',
        component: () => import( '@/viewer/excel.vue'),
        meta: {
            title: '表格预览'
        }
    },
    {
        path: '/',
        name: 'viewer',
        component: () => import( '@/viewer/index.vue'),
        meta: {
            title: '表格预览'
        }
    }
]
